<template>
    <div class="cookie_popup" v-if="showPopup">
        <p class="mb-0">We use cookies to provide a personalized user experience. By continuing to use & browse this
            site, you agree to our <NuxtLink :to="localePath('/cookie-policy')"> Cookie Policy.</NuxtLink>
        </p>
        <div class="got_it" @click="showPopup = false">GOT IT</div>
    </div>
</template>

<script setup>
const localePath = useLocalePath()
import { popupStore } from '@/stores/popup/popup';

const showPopup = ref(false)
const module = ref('cookie')
const store = popupStore()

onMounted(async () => {
    const status = await store.getPopupFlag(module.value)
    if (!status) {
        setTimeout(() => { showPopup.value = true }, 1500)
        store.setPopupFlag(module.value)
    }
})



</script>

<style scoped>
.cookie_popup {
    padding: 12px 40px;
    border-radius: 4px;
    border: solid 1px #606060;
    background-color: rgba(0, 0, 0, 0.5);
    width: max-content;
    max-width: 1130px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
}

.cookie_popup p {
    color: #fff;
}

.cookie_popup a {
    color: #fff;
    text-decoration: underline;
}

.cookie_popup .got_it {
    border: 1px solid #fff;
    min-width: 100px;
    text-align: center;
    color: #fff;
    padding: 1px;
    font-size: 14px;
    letter-spacing: 0.5px;
    margin-left: 20px;
    cursor: pointer;
}

@media(max-width: 1120px) {
    .cookie_popup {
        width: 100%;
    }
}

@media(max-width: 767px) {
    .cookie_popup {
        flex-wrap: wrap;
        justify-content: center;
        bottom: 0;
        margin-left: 0px;
    }

    .cookie_popup p {
        text-align: center;
        padding-bottom: 20px;
        font-size: 14px;
    }

    .cookie_popup .got_it {
        border: 1px solid #fff;
        min-width: 100px;
        text-align: center;
        color: #fff;
        padding: 1px;
        font-size: 14px;
        letter-spacing: 0.5px;
        margin-left: 0px;
        cursor: pointer;
    }
}
</style>